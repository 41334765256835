/** reused styles */

:root {
  --dark-bg: rgba(0, 0, 0, 0.315);
}

.cos-hidden {
  opacity: 0;
  pointer-events: none;
  /* Add transition on the element so that fade in and fade out can both work */
  /* transition: opacity 0.3s ease; */
}

.hidden {
  visibility: hidden;
}

/** full size container */
.controls-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/** --- mute --- */

.controls-mute {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: var(--dark-bg);

  border-radius: var(--toggle-height);
  width: auto;
  display: flex;
  flex-direction: row-reverse;

  transition: opacity 0.3s ease;
}
.controls-mute-icon {
  background: center content-box no-repeat url(../images/volume_off_white_36dp.svg);
  width: var(--toggle-height);
  padding: calc(var(--toggle-height) / 2) calc(var(--toggle-height) * 0.7);
  padding-right: 0;
  height: auto;
}
#toggle-mute:checked ~ .controls-mute-icon {
  background: center content-box no-repeat url(../images/volume_up_white_36dp.svg);
}

/* toggle checkbox */
:root {
  --white: rgb(229,236,229);
  --gray: rgb(52,36,30);
  --green: rgb(58,182,78);
  --toggle-height: 32px;
}

input[type="checkbox"], label {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

label {
  cursor: pointer;
}

input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.toggle label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(var(--toggle-height) / 2);
}

.toggle span {
  position: relative;
  width: calc(var(--toggle-height) + 24px);
  height: var(--toggle-height);
  border-radius: calc(var(--toggle-height) / 2);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: var(--gray);
  transition: all 0.24s;
}

.toggle span::before,
.toggle span::after {
  content: "";
  position: absolute;
}
/* knob */
.toggle span::before {
  --inset: 3px;
  left: var(--inset);
  top: var(--inset);
  width: calc(var(--toggle-height) - var(--inset) * 2);
  height: calc(var(--toggle-height) - var(--inset) * 2);
  background: var(--white);
  border-radius: 50%;
  z-index: 1;
  transition: transform 0.24s;
}

/* tick */
.toggle span::after {
  top: 50%;
  right: 8px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  background-size: 12px 12px;
}

.toggle [type="checkbox"]:checked + label span {
  background: var(--green);
}

.toggle [type="checkbox"]:checked + label span::before {
  transform: translateX(24px);
}

.toggle [type="checkbox"]:checked + label span::after {
  --size: 15px;
  
  width: var(--size);
  height: var(--size);
  left: calc(var(--size) / 2);
  background-image: url(../images/check_tick.svg);
  background-size: var(--size) var(--size);
}

/* ------------ */

/* --- play button --- */

.controls-play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 76px;
  width: 120px;
  background-color: var(--dark-bg);
  border-radius: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.controls-play-button-icon {
  height: 64px;
  width: auto;
}

/* ------------------- */
