:root {
  --dark-bg: #00000050;
}

.cos-hidden {
  opacity: 0;
  pointer-events: none;
}

.hidden {
  visibility: hidden;
}

.controls-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.controls-mute {
  background-color: var(--dark-bg);
  border-radius: var(--toggle-height);
  width: auto;
  flex-direction: row-reverse;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.controls-mute-icon {
  width: var(--toggle-height);
  padding: calc(var(--toggle-height) / 2) calc(var(--toggle-height) * .7);
  height: auto;
  background: url("volume_off_white_36dp.b5b8c667.svg") center no-repeat content-box content-box;
  padding-right: 0;
}

#toggle-mute:checked ~ .controls-mute-icon {
  background: url("volume_up_white_36dp.66bf77cb.svg") center no-repeat content-box content-box;
}

:root {
  --white: #e5ece5;
  --gray: #34241e;
  --green: #3ab64e;
  --toggle-height: 32px;
}

input[type="checkbox"], label {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.toggle label {
  padding: calc(var(--toggle-height) / 2);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.toggle span {
  width: calc(var(--toggle-height)  + 24px);
  height: var(--toggle-height);
  border-radius: calc(var(--toggle-height) / 2);
  background: var(--gray);
  transition: all .24s;
  position: relative;
  box-shadow: inset 0 0 5px #0006;
}

.toggle span:before, .toggle span:after {
  content: "";
  position: absolute;
}

.toggle span:before {
  --inset: 3px;
  left: var(--inset);
  top: var(--inset);
  width: calc(var(--toggle-height)  - var(--inset) * 2);
  height: calc(var(--toggle-height)  - var(--inset) * 2);
  background: var(--white);
  z-index: 1;
  border-radius: 50%;
  transition: transform .24s;
}

.toggle span:after {
  width: 12px;
  height: 12px;
  background-size: 12px 12px;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.toggle [type="checkbox"]:checked + label span {
  background: var(--green);
}

.toggle [type="checkbox"]:checked + label span:before {
  transform: translateX(24px);
}

.toggle [type="checkbox"]:checked + label span:after {
  --size: 15px;
  width: var(--size);
  height: var(--size);
  left: calc(var(--size) / 2);
  background-image: url("check_tick.4a49e0f1.svg");
  background-size: var(--size) var(--size);
}

.controls-play-button {
  height: 76px;
  width: 120px;
  background-color: var(--dark-bg);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.controls-play-button-icon {
  height: 64px;
  width: auto;
}

/*# sourceMappingURL=index.77bb0f9f.css.map */
